import { createRouter, createWebHistory } from "vue-router";
import HomeView from "./views/en/HomeEn.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/about/en",
    name: "About",
    component: () => import("./views/en/AboutView.vue"),
    meta: {
      testimoniosusuaiosEn: true,
      menuEs: true,
      contactformEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/Blog/en",
    name: "Blog",
    component: () => import("./views/en/BlogView.vue"),
    meta: {
      testimoniosusuaiosEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service",
    name: "Service",
    component: HomeView,
    meta: {
      title: "PSOFNTECH - HOME",
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/sub/LaptopRepair/:rout/en",
    name: "Laptop Repair",
    component: () => import("./views/en/LaptopRepair.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
    },
  },
  {
    path: "/service/sub/DigitalMarketing/:rout/en",
    name: "Digital Marketing",
    component: () => import("./views/en/DigitalMarketing.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
    },
  },
  {
    path: "/service/OnsiteComputerRepair/en",
    name: "OnsiteComputerRepair",
    component: () => import("./views/en/OnsiteComputerRepair.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      contactformEn: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/WebDesign/en",
    name: "Web Design",
    component: () => import("./views/en/WebDesing.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/AppDevelopment/en",
    name: "App Development",
    component: () => import("./views/en/AppDevelopment.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/MerchantServices/en",
    name: "Merchant Services",
    component: () => import("./views/en/MerchantServices.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      contactformEn: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/:type/en",
    name: "Services",
    component: () => import("./views/en/ServiceView.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      contactformEn: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/sub/GraphicDesign/:rout/en",
    name: "Service Graphic Design",
    component: () => import("./views/en/SubGraphics.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
    },
  },
  {
    path: "/service/sub/:type/en",
    name: "Cards",
    component: () => import("./views/en/WithCards.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
    },
  },
  {
    path: "/service/sub/:type/:rout/en",
    name: "Ruta1",
    component: () => import("./views/en/SubServices.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
    },
  },
  /*----------------PARTE Spanish--------------------------------------- */
  {
    path: "/about/es",
    name: "About Es",
    component: () => import("./views/es/HomeEs.vue"),
    meta: {
      layout: "LayoutEs",
      title: "PSOFNTECH - INICIO",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/resource/es",
    name: "Resource Es",
    component: () => import("./views/es/HomeEs.vue"),
    meta: {
      layout: "LayoutEs",
      title: "PSOFNTECH - INICIO",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/Blog/es",
    name: "EsBlog",
    component: () => import("./views/es/BlogViewEs.vue"),
    meta: {
      layout: "LayoutEs",
      testimoniosusuaiosEs: true,
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/es",
    name: "EsHome",
    component: () => import("./views/es/HomeEs.vue"),
    meta: {
      layout: "LayoutEs",
      title: "PSOFNTECH - INICIO",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service",
    name: "Service Spanish",
    component: () => import("./views/es/HomeEs.vue"),
    meta: {
      layout: "LayoutEs",
      title: "PSOFNTECH - INICIO",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/service/sub/:type/es",
    name: ":type",
    component: () => import("./views/es/WithCardsEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
      contactformEs: true,
    },
  },
  {
    path: "/service/OnsiteComputerRepair/es",
    name: "OnsiteComputerRepairEs",
    component: () => import("./views/es/OnsiteComputerRepairEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      contactformEs: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/WebDesign/es",
    name: "WebDesign2ES",
    component: () => import("./views/es/WebDesingEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/AppDevelopment/es",
    name: "App2ES",
    component: () => import("./views/es/AppDevelopmentEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/MerchantServices/es",
    name: "MerchaES",
    component: () => import("./views/es/MerchantServicesEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      contactformEs: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/:type/es",
    name: "SimpleES",
    component: () => import("./views/es/ServiceViewEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      contactformEs: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/sub/GraphicDesign/:rout/es",
    name: "RutaES",
    component: () => import("./views/es/SubGraphicsEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
      contactformEs: true,
    },
  },

  {
    path: "/service/sub/:type/:rout/es",
    name: "Ruta1Es",
    component: () => import("./views/es/SubServicesEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
    },
  },

  /*----------------PARTE BACKK--------------------------------------- */
  {
    path: "/login",
    name: "login",
    component: () => import("./views/CMS/LoginView.vue"),
    meta: {
      layout: "LoginLayout",
      title: "PSOFNTECH - LOGIN",
    },
  },
  {
    path: "/users",
    name: "User",
    component: () => import("./views/CMS/UsersView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - USERS",
    },
  },
  {
    path: "/pages",
    name: "Pages CMS",
    component: () => import("./views/CMS/PagesView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },

  {
    path: "/pages/:id",
    name: "Page",
    component: () => import("./views/CMS/PageView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PAGE",
    },
  },

  {
    path: "/testimonials",
    name: "Testimonials",
    component: () => import("./views/CMS/Testimonial/TestimonialView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - TESTIMOANIALS",
    },
  },

  {
    path: "/testimonial-create",
    name: "Create Testimonial",
    component: () => import("./views/CMS/Testimonial/components/CreateComponent.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - TESTIMONIAL"
    }
  },

  {
    path: "/testimonial-edit/:id",
    name: "Update Testimonial",
    component: () => import("./views/CMS/Testimonial/components/EditComponent.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - TESTIMONIAL"
    }
  },

  {
    path: '/testimonial/:token',
    name: 'testimonial-form',
    component: () => import("./views/en/TestimonialForm.vue"),
    meta: {
      title: "PSOFNTECH - TESTIMONIAL"
    },
  },

  {
    path: "/site",
    name: "Site",
    component: () => import("./views/CMS/SiteView/ContactInfo.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - CONTACT",
    },
  },
  {
    path: "/site/SEO",
    name: "Site-SEO",
    component: () => import("./views/CMS/SiteView/SeoInfo.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - SEO",
    },
  },
  {
    path: "/site/general-configuration",
    name: "Configuration",
    component: () => import("./views/CMS/SiteView/GeneralConfig.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - CONFIGURATION",
    },
  },
  {
    path: "/site/Contact",
    name: "Contact",
    component: () => import("./views/CMS/SiteView/ContactInfo.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - CONTACT",
    },
  },
  {
    path: "/site/Banner",
    name: "Banner",
    component: () => import("./views/CMS/SiteView/BannerIndex.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - BANNER",
    },
  },
  {
    path: "/site/BannerSubServicios",
    name: "Parallax",
    component: () => import("./views/CMS/SiteView/BannerSubServicios.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - BANNER",
    },
  },
  {
    path: "/site/Business",
    name: "Business",
    component: () => import("./views/CMS/SiteView/BusinessInfo.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - BUSINESS",
    },
  },
  {
    path: "/portfolio",
    name: "Portafolios",
    component: () => import("./views/CMS/Portfolio/PortfolioTable.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PAGES",
    },
  },
  {
    path: "/portfolio/:rout",
    name: "Portfolios",
    component: () => import("./views/CMS/Portfolio/PortfolioTable.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIOS",
    },
  },
  {
    path: "/portfolio/:rout/:id",
    name: "Portfolio",
    component: () => import("./views/CMS/Portfolio/PortfolioView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIOS",
    },
  },
  {
    path: "/blogCMS",
    name: "Blogs CMS",
    component: () => import("./views/CMS/Blog/Blogs.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/blogCMS/:id",
    name: "Blog CMS",
    component: () => import("./views/CMS/Blog/Blog.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/aboutCMS",
    name: "About US CMS",
    component: () => import("./views/CMS/About/AboutUS.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - ABOUT US",
    },
  },
  {
    path: "/servicescms",
    name: "Services CMS",
    component: () => import("./views/CMS/Servicios/PackagesTable.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/servicescms/Package",
    name: "Package",
    component: () => import("./views/CMS/Servicios/PackagesTable.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/servicescms/Package/:id",
    name: "Package Edit",
    component: () => import("./views/CMS/Servicios/PackageView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/servicescms/services",
    name: "All Services",
    component: () => import("./views/CMS/Servicios/ServiciosView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import("./views/CMS/Menu/MenuView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - MENU",
    },
  },


  /* added as of 10/18/22 */

  {
    path: "/webdesign/ny/ridgewood/queens/en",
    name: "Web Design | New York (NY) | RIDGEWOOD, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingRIDGEWOOD-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/ridgewood/queens/es",
    name: "Diseño Web | New York (NY) | RIDGEWOOD, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingRIDGEWOOD-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/glendale/queens/en",
    name: "Web Design | New York (NY) | GLENDALE, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingGLENDALE-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/glendale/queens/es",
    name: "Diseño Web | New York (NY) | GLENDALE, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingGLENDALE-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/webdesign/ny/maspeth/queens/en",
    name: "Web Design | New York (NY) | MASPETH, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingMASPETH-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/maspeth/queens/es",
    name: "Diseño Web | New York (NY) | MASPETH, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingMASPETH-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/laurel-hill/queens/en",
    name: "Web Design | New York (NY) | Laurel Hill, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingLaurelHill-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/laurel-hill/queens/es",
    name: "Diseño Web | New York (NY) | Laurel Hill, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingLaurelHill-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/middle-village/queens/en",
    name: "Web Design | New York (NY) | Middle Village, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingMiddleVillage-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/middle-village/queens/es",
    name: "Diseño Web | New York (NY) | Middle Village, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingMiddleVillage-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/blissville/queens/en",
    name: "Web Design | New York (NY) | Blissville, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingBlissville-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/blissville/queens/es",
    name: "Diseño Web | New York (NY) | Blissville, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingBlissville-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/corona-east-elmhurst/queens/en",
    name: "Web Design | New York (NY) | Corona East Elmhurst, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingCoronaEastElmhurst-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/corona-east-elmhurst/queens/es",
    name: "Diseño Web | New York (NY) | Corona East Elmhurst, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingCoronaEastElmhurst-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/elmhurst/queens/en",
    name: "Web Design | New York (NY) | Elmhurst, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingElmhurst-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/elmhurst/queens/es",
    name: "Diseño Web | New York (NY) | Elmhurst, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingElmhurst-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/jackson-heights/queens/en",
    name: "Web Design | New York (NY) | Jackson Heights, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingJacksonHeights-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/jackson-heights/queens/es",
    name: "Diseño Web | New York (NY) | Jackson Heights, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingJacksonHeights-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/woodside/queens/en",
    name: "Web Design | New York (NY) | Woodside, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingWoodside-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/woodside/queens/es",
    name: "Diseño Web | New York (NY) | Woodside, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingWoodside-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/sunnysude-gardens/queens/en",
    name: "Web Design | New York (NY) | Sunnysude Gardens, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingSunnysudeGardens-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/sunnysude-gardens/queens/es",
    name: "Diseño Web | New York (NY) | Sunnysude Gardens, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingSunnysudeGardens-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/sunnyside/queens/en",
    name: "Web Design | New York (NY) | Sunnyside, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingSunnyside-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/sunnyside/queens/es",
    name: "Diseño Web | New York (NY) | Sunnyside, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingSunnyside-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  
  /* / routes new jersey */

  {
    path: "/webdesign/ny/bergenfield/new-jersey/en",
    name: "Web Design | New York (NY) | Bergenfield, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingBergenfield-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/bergenfield/new-jersey/es",
    name: "Diseño Web | New York (NY) | Bergenfield, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingBergenfield-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/webdesign/ny/allendale/new-jersey/en",
    name: "Web Design | New York (NY) | Allendale, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingAllendale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/allendale/new-jersey/es",
    name: "Diseño Web | New York (NY) | Allendale, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingAllendale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/alpine/new-jersey/en",
    name: "Web Design | New York (NY) | Alpine, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingAlpine-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/alpine/new-jersey/es",
    name: "Diseño Web | New York (NY) | Alpine, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingAlpine-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/bogota/new-jersey/en",
    name: "Web Design | New York (NY) | Bogota, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingBogota-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/bogota/new-jersey/es",
    name: "Diseño Web | New York (NY) | Bogota, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingBogota-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/caristadt/new-jersey/en",
    name: "Web Design | New York (NY) | Caristadt, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingCaristadt-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/caristadt/new-jersey/es",
    name: "Diseño Web | New York (NY) | Caristadt, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingCaristadt-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/cliffside/new-jersey/en",
    name: "Web Design | New York (NY) | Cliffside, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingCliffside-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/cliffside/new-jersey/es",
    name: "Diseño Web | New York (NY) | Cliffside, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingCliffside-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/park-closter/new-jersey/en",
    name: "Web Design | New York (NY) | Park Closter, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingParkCloster-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/park-closter/new-jersey/es",
    name: "Diseño Web | New York (NY) | Park Closter, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingParkCloster-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/cresskill/new-jersey/en",
    name: "Web Design | New York (NY) | Cresskill, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingCliffside-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/cresskill/new-jersey/es",
    name: "Diseño Web | New York (NY) | Cresskill, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingCresskill-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/demarest/new-jersey/en",
    name: "Web Design | New York (NY) | Demarest, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingDemarest-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/demarest/new-jersey/es",
    name: "Diseño Web | New York (NY) | Demarest, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingDemarest-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/dumont/new-jersey/en",
    name: "Web Design | New York (NY) | Dumont, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingDumont-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/dumont/new-jersey/es",
    name: "Diseño Web | New York (NY) | Dumont, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingDumont-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/east-rutherford/new-jersey/en",
    name: "Web Design | New York (NY) | East Rutherford, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEastRutherford-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/east-rutherford/new-jersey/es",
    name: "Diseño Web | New York (NY) | East Rutherford, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEastRutherford-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/edgewater/new-jersey/en",
    name: "Web Design | New York (NY) | Edgewater, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEdgewater-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/edgewater/new-jersey/es",
    name: "Diseño Web | New York (NY) | Edgewater, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEdgewater-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/elmwood-park/new-jersey/en",
    name: "Web Design | New York (NY) | Elmwood Park, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingElmwoodPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/elmwood-park/new-jersey/es",
    name: "Diseño Web | New York (NY) | Elmwood Park, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingElmwoodPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/emerson/new-jersey/en",
    name: "Web Design | New York (NY) | Emerson, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEmerson-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/emerson/new-jersey/es",
    name: "Diseño Web | New York (NY) | Emerson, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEmerson-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/englewood/new-jersey/en",
    name: "Web Design | New York (NY) | Englewood, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEnglewood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/englewood/new-jersey/es",
    name: "Diseño Web | New York (NY) | Englewood, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEnglewood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/paramus/new-jersey/en",
    name: "Web Design | New York (NY) | Paramus, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingParamus-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/paramus/new-jersey/es",
    name: "Diseño Web | New York (NY) | Paramus, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingParamus-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/teterboro/new-jersey/en",
    name: "Web Design | New York (NY) | Teterboro, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingTeterboro-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/teterboro/new-jersey/es",
    name: "Diseño Web | New York (NY) | Teterboro, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingTeterboro-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/moonachie/new-jersey/en",
    name: "Web Design | New York (NY) | Moonachie, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingMoonachie-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/moonachie/new-jersey/es",
    name: "Diseño Web | New York (NY) | Moonachie, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingMoonachie-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/kort-lee/new-jersey/en",
    name: "Web Design | New York (NY) | Fort Lee, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingFortLee-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/kort-lee/new-jersey/es",
    name: "Diseño Web | New York (NY) | Fort Lee, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingFortLee-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/lodi/new-jersey/en",
    name: "Web Design | New York (NY) | Lodi, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingLodi-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/lodi/new-jersey/es",
    name: "Diseño Web | New York (NY) | Lodi, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingLodi-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/little-ferry/new-jersey/en",
    name: "Web Design | New York (NY) | Little Ferry, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingLittleFerry-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/little-ferry/new-jersey/es",
    name: "Diseño Web | New York (NY) | Little Ferry, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingLittleFerry-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/hackensack/new-jersey/en",
    name: "Web Design | New York (NY) | Hackensack, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingHackensack-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/hackensack/new-jersey/es",
    name: "Diseño Web | New York (NY) | Hackensack, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingHackensack-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  // Brooklyn

  {
    path: "/webdesign/ny/bedstuy/brooklyn/en",
    name: "Web Design | New York (NY) | BEDSTUY, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingBEDSTUY-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/bedstuy/brooklyn/es",
    name: "Diseño Web | New York (NY) | BEDSTUY, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingBEDSTUY-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/williamsburg/brooklyn/en",
    name: "Web Design | New York (NY) | Williamsburg, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingWilliamsburg-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/williamsburg/brooklyn/es",
    name: "Diseño Web | New York (NY) | Williamsburg, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingWilliamsburg-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/dumbo/brooklyn/en",
    name: "Web Design | New York (NY) | Dumbo, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingDumbo-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/dumbo/brooklyn/es",
    name: "Diseño Web | New York (NY) | Dumbo, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingDumbo-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/bushwick/brooklyn/en",
    name: "Web Design | New York (NY) | Bushwick, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingBushwick-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/bushwick/brooklyn/es",
    name: "Diseño Web | New York (NY) | Bushwick, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingBushwick-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/greenpoint/brooklyn/en",
    name: "Web Design | New York (NY) | Greenpoint, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingGreenpoint-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/greenpoint/brooklyn/es",
    name: "Diseño Web | New York (NY) | Greenpoint, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingGreenpoint-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/fort-greene/brooklyn/en",
    name: "Web Design | New York (NY) | Fort Greene, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingFortGreene-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/fort-greene/brooklyn/es",
    name: "Diseño Web | New York (NY) | Fort Greene, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingFortGreene-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/east-flatbush/brooklyn/en",
    name: "Web Design | New York (NY) | East Flatbush, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingEastFlatbush-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/east-flatbush/brooklyn/es",
    name: "Diseño Web | New York (NY) | East Flatbush, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingEastFlatbush-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/park-slope/brooklyn/en",
    name: "Web Design | New York (NY) | Park Slope, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingParkSlope-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/park-slope/brooklyn/es",
    name: "Diseño Web | New York (NY) | Park Slope, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingParkSlope-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
 
  // laptop repair NewYork

  {
    path: "/laptop-repair/ny/washington-heights/new-york/en",
    name: "Web Design | New York (NY) | Washington Heights, New York",
    component: () => import("./views/en/address/laptop-repair/NewYork/WebDesingWashingtonHeights-NewYork.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: false,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/washington-heights/new-york/es",
    name: "Diseño Web | New York (NY) | Washington Heights, New York",
    component: () => import("./views/es/address/laptop-repair/NewYork/WebDesingWashingtonHeights-NewYork-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  
  /* / added as of 10/18/22 */



  /*--------------END PARTE BACKKK----------------------------------------- */
  {
    path: "/:pathMatch(.*)*/",
    name: "404",
    component: HomeView,
    meta: {
      auth: true,
      title: "PSOFNTECH - 404",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // document.title = `${to.params.type}`;
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
  next();
});

export default router;
